import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueGtag from 'vue-gtag';
import router from './plugins/router';
import pinia from './plugins/pinia';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import './styles/global.scss';

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_TRACK_ID },
}, router);

new Vue({
  router,
  pinia,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
