<template>
  <v-dialog
    :value="opened" max-width="500px"
    :overlay-color="isDarkMode ? 'white' : undefined"
    :overlay-opacity="isDarkMode ? 0.25 : undefined"
    @input="$emit('update:opened', $event);"
  >
    <v-card>
      <v-img
        contain
        class="grey lighten-2"
        :class="{ 'rainbow-background': sheet.isSpecial }"
        :height="$vuetify.breakpoint.height < 750 ? '250px' : '350px'"
        :src="imageSrc"
        @error="imageErrorOccurred = true;"
      >
        <img
          :src="imageSrc"
          style="position: absolute; height: 100%; width: 100%; object-fit: contain; opacity: 0;"
          alt=""
        >
        <div
          v-if="!isStatic"
          class="d-flex justify-center align-center fill-height text-h1 white--text"
        >
          {{ sheet.songNo }}
        </div>
        <div
          v-else
          class="d-flex justify-end align-end fill-height pa-3"
        >
          <v-tooltip v-if="sheet.searchUrl !== null" top>
            <template #activator="{ on }">
              <v-btn
                :fab="$vuetify.breakpoint.xsOnly"
                :text="$vuetify.breakpoint.smAndUp"
                dark :color="getSearchLinkColor(sheet)"
                :href="getYouTubeSearchLink(sheet)" target="_blank"
                v-on="on"
                @click="$gtag.event('SheetVideoSearched', { value: 1 });"
              >
                <v-icon :large="$vuetify.breakpoint.smAndUp">
                  {{ getSearchLinkIcon(sheet) }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ sheet.searchUrl === undefined ? $t('searchOnYouTube') : ':3' }}</span>
          </v-tooltip>
        </div>
        <img
          v-if="getTypeIconUrl(sheet.type) != null"
          :src="getTypeIconUrl(sheet.type)"
          style="position: absolute; bottom: 10px; left: 15px;"
          :alt="`${$t('key.type')}: ${sheet.type}`"
        >
      </v-img>
      <v-card-text class="py-5">
        <div
          class="d-flex flex-column justify-start align-end"
          style="position: absolute; right: 24px;"
        >
          <h3 v-if="sheet.bpm !== undefined">
            BPM {{ sheet.bpm !== null ? sheet.bpm : '?' }}
          </h3>
          <v-spacer class="py-1" />
          <v-tooltip v-if="sheet.isLocked" top>
            <template #activator="{ on }">
              <v-icon large class="pa-1" v-on="on">
                mdi-lock
              </v-icon>
            </template>
            <span>{{ $t('tooltip.unlockNeeded') }}</span>
          </v-tooltip>
          <template v-for="({ region, name: regionName }, i) in regions">
            <v-tooltip v-if="sheet.regions && sheet.regions[region] === false" :key="i" top>
              <template #activator="{ on }">
                <v-icon large class="pa-1" v-on="on">
                  {{ region === 'jp' ? 'mdi-circle-off-outline' : '' }}
                  {{ region === 'intl' ? 'mdi-earth-off' : '' }}
                  {{ region === 'cn' ? 'mdi-earth-box-off' : '' }}
                </v-icon>
              </template>
              <span>
                {{ $t('tooltip.unavailableInRegion', { region: regionName }) }}
              </span>
            </v-tooltip>
          </template>
        </div>
        <div>
          <h3>
            {{
              sheet.gameTitle ? `${sheet.gameTitle} ＼ ` : null
            }}{{
              (sheet.category || 'N/A').replaceAll('|', '｜')
            }}
          </h3>
          <v-tooltip top>
            <template #activator="{ on }">
              <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
              <h1
                class="py-2 text-truncate"
                :class="{ 'text--primary': isStatic }"
                style="cursor: pointer; user-select: all; margin-right: 42px;"
                v-on="on"
                @click="copyText(sheet.title);"
              >
                {{ sheet.title }}
              </h1>
            </template>
            <span>{{ sheet.title }}</span>
          </v-tooltip>
          <h4
            class="pb-2 text-truncate"
            style="margin-right: 42px;"
          >
            {{ sheet.artist || 'N/A' }}
          </h4>
          <h2 class="py-2">
            <span
              :class="{ 'text--primary': getDifficultyColor(sheet.difficulty) === 'unset' }"
              :style="{ 'color': getDifficultyColor(sheet.difficulty) }"
            >
              <span v-if="getDifficultyIconUrl(sheet.difficulty) != null">
                <img
                  :src="getDifficultyIconUrl(sheet.difficulty)"
                  width="25" height="25"
                  class="mr-1"
                  style="vertical-align: middle;"
                  :alt="`${$t('key.difficulty')}: ${sheet.difficulty}`"
                >
              </span>
              <span>
                {{ getDifficultyName(sheet.difficulty) }}
              </span>
              <span>
                {{ sheet.level }}
              </span>
              <v-tooltip
                v-if="sheet.internalLevel != null"
                top
              >
                <template #activator="{ on }">
                  <small class="text--secondary" v-on="on">
                    <span>({{ sheet.internalLevel }})</span>
                  </small>
                </template>
                <span v-text="$t('key.internalLevel')" />
              </v-tooltip>
            </span>
          </h2>
          <h4 class="pt-2 text-truncate">
            {{ $t('key.noteDesigner') }}: {{ sheet.noteDesigner || 'N/A' }}
          </h4>
          <h4 class="pt-2 text-truncate">
            {{ $t('key.releaseDate') }}: {{ sheet.releaseDate || '????-??-??' }}
            ({{ sheet.version || '???' }})
          </h4>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="isDrawMode" color="primary" text @click="$emit('redraw-sheet');">
          {{ $t('tryAgain') }}
        </v-btn>
        <v-btn color="success" text @click="$emit('update:opened', false);">
          {{ isDrawMode ? $t('ui.ok') : $t('ui.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="copyHintOpened" :timeout="750">
      <v-icon left color="success">
        mdi-content-copy
      </v-icon>
      {{ $t('ui.copied') }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import * as Pinia from 'pinia';
import useRootStore from '@/stores/root';
import copy from 'copy-to-clipboard';
import cat from '@/assets/cat.png';

export default {
  inject: ['gameCode'],
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
    sheet: {
      type: Object,
      required: true,
    },
    isDrawMode: {
      type: Boolean,
    },
    isStatic: {
      type: Boolean,
    },
  },
  data() {
    return {
      imageErrorOccurred: false,
      copyHintOpened: false,
    };
  },
  computed: {
    ...Pinia.mapState(useRootStore, [
      'songs',
      'regions',
      'getTypeIconUrl',
      'getDifficultyIconUrl',
      'getDifficultyName',
      'getDifficultyColor',
      'getYouTubeSearchLink',
    ]),
    imageSrc() {
      if (this.imageErrorOccurred) return cat;
      return this.isStatic ? this.sheet.imageUrl : undefined;
    },
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  watch: {
    sheet() {
      this.imageErrorOccurred = false;
    },
  },
  methods: {
    getSearchLinkIcon(sheet) {
      if (sheet.searchUrl === null) return null;
      if (sheet.searchUrl === undefined) return 'mdi-youtube';
      return 'mdi-link-variant';
    },
    getSearchLinkColor(sheet) {
      if (sheet.searchUrl === null) return null;
      if (sheet.searchUrl === undefined) return 'red';
      return 'primary';
    },
    copyText(str) {
      copy(str, {
        format: 'text/plain',
      });
      this.copyHintOpened = true;
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "searchOnYouTube": "Search on YouTube",
    "tryAgain": "TRY AGAIN"
  },
  "ja": {
    "searchOnYouTube": "YouTube で検索",
    "tryAgain": "もう一回"
  },
  "zh-Hant": {
    "searchOnYouTube": "在 YouTube 上搜尋",
    "tryAgain": "再抽一次"
  },
  "zh-Hans": {
    "searchOnYouTube": "在 YouTube 上搜索",
    "tryAgain": "再抽一次"
  }
}
</i18n>
