<template>
  <v-dialog
    v-model="isOpened"
    max-width="500px"
  >
    <v-card class="text-center">
      <v-card-title class="justify-center">
        {{ $t('newVersionAvailable.title') }}
      </v-card-title>

      <v-divider class="mx-4" />

      <div class="py-5">
        <v-card-text
          class="text-pre-wrap"
          v-text="$t('newVersionAvailable.message')"
        />
        <v-btn
          text
          color="primary"
          class="text-none"
          :href="`https://arcade-songs.zetaraku.dev/`"
          v-text="`arcade-songs.zetaraku.dev`"
        />
      </div>

      <v-divider class="mx-4" />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="success"
          @click="isOpened = false;"
        >
          {{ $t('ui.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  inject: ['gameCode'],
  data() {
    return {
      isOpened: true,
    };
  },
};
</script>
