<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-if="filterOptions.categories !== null"
        cols="12" md="6" class="d-flex align-end"
      >
        <v-select
          v-model="filters.categories"
          :items="filterOptions.categories||[]"
          prepend-icon="mdi-folder-multiple-outline"
          :label="$t('key.category')"
          :placeholder="$t('ui.all')"
          persistent-placeholder
          multiple chips deletable-chips
          clearable
        />
      </v-col>
      <v-col
        v-if="filterOptions.titles !== null"
        cols="12" md="6" class="d-flex align-end"
      >
        <v-combobox
          v-model="filters.title"
          :items="filterOptions.titles||[]"
          prepend-icon="mdi-alphabetical-variant"
          :label="$t('key.title')"
          :placeholder="$t('ui.all')"
          persistent-placeholder
          clearable
          :allow-overflow="false"
        />
      </v-col>
      <v-col
        v-if="filterOptions.difficulties !== null"
        cols="12" md="6" class="d-flex align-end"
      >
        <v-select
          v-model="filters.difficulties"
          :items="filterOptions.difficulties||[]"
          prepend-icon="mdi-skull-outline"
          :label="$t('key.difficulty')"
          :placeholder="$t('ui.all')"
          persistent-placeholder
          multiple chips deletable-chips
          clearable
        />
      </v-col>
      <v-col
        v-if="filterOptions.levels !== null"
        cols="12" md="6" class="d-flex align-end"
      >
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col cols="6" class="d-flex align-end">
              <v-select
                v-model="filters.minLevelValue"
                :items="filterOptions.levels||[]"
                prepend-icon="mdi-numeric-9-plus-box-multiple-outline"
                :label="$t('key.minLevel')"
                :placeholder="(filterOptions.levels[0]||{ text: '?' }).text"
                persistent-placeholder
                clearable
              />
            </v-col>
            <v-col cols="6" class="d-flex align-end pl-8">
              <v-select
                v-model="filters.maxLevelValue"
                :items="filterOptions.levels||[]"
                :label="$t('key.maxLevel')"
                :placeholder="(filterOptions.levels.slice(-1)[0]||{ text: '?' }).text"
                persistent-placeholder
                clearable
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        v-if="filterOptions.versions !== null"
        cols="12" md="6" class="d-flex align-end"
      >
        <v-select
          v-model="filters.versions"
          :items="filterOptions.versions||[]"
          prepend-icon="mdi-star-outline"
          :label="$t('key.version')"
          :placeholder="$t('ui.all')"
          persistent-placeholder
          multiple chips deletable-chips
          clearable
        />
      </v-col>
      <v-col
        v-if="filterOptions.types !== null"
        cols="12" md="6" class="d-flex align-end"
      >
        <v-select
          v-model="filters.types"
          :items="filterOptions.types||[]"
          prepend-icon="mdi-assistant"
          :label="$t('key.type')"
          :placeholder="$t('ui.all')"
          persistent-placeholder
          multiple chips deletable-chips
          clearable
        />
      </v-col>
      <v-col
        v-if="filterOptions.artists !== null"
        cols="12" md="6" class="d-flex align-end"
      >
        <v-combobox
          v-model="filters.artist"
          :items="filterOptions.artists||[]"
          prepend-icon="mdi-account-music-outline"
          :label="$t('key.artist')"
          :placeholder="$t('ui.all')"
          persistent-placeholder
          clearable
          :allow-overflow="false"
        />
      </v-col>
      <v-col
        v-if="filterOptions.noteDesigners !== null"
        cols="12" md="6" class="d-flex align-end"
      >
        <v-select
          v-model="filters.noteDesigners"
          :items="filterOptions.noteDesigners||[]"
          prepend-icon="mdi-account-edit-outline"
          :label="$t('key.noteDesigner')"
          :placeholder="$t('ui.all')"
          persistent-placeholder
          multiple chips deletable-chips
          clearable
        />
      </v-col>
      <v-col
        v-if="filterOptions.regions$t($t.bind(this)) !== null"
        cols="12" md="6" class="d-flex align-end"
      >
        <v-select
          v-model="filters.region"
          :items="filterOptions.regions$t($t.bind(this))||[]"
          prepend-icon="mdi-map-search"
          :label="$t('key.region')"
          :placeholder="$t('ui.all')"
          persistent-placeholder
          clearable
        />
      </v-col>
      <v-col
        v-if="filterOptions.bpms !== null"
        cols="12" md="6" class="d-flex align-end"
      >
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col cols="6" class="d-flex align-end">
              <v-text-field
                v-model.number="filters.minBPM"
                type="number" :min="0"
                prepend-icon="mdi-metronome"
                :label="$t('key.minBPM')"
                :placeholder="String(filterOptions.bpms[0] || 0)"
                persistent-placeholder
                clearable
              />
            </v-col>
            <v-col cols="6" class="d-flex align-end pl-8">
              <v-text-field
                v-model.number="filters.maxBPM"
                type="number" :min="0"
                :label="$t('key.maxBPM')"
                :placeholder="String(filterOptions.bpms.slice(-1)[0] || 999)"
                persistent-placeholder
                clearable
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as Pinia from 'pinia';
import useRootStore from '@/stores/root';
import { loadFiltersFromQuery } from '@/utils';

export default {
  data() {
    return {
      filters: {
        categories: [],
        title: null,
        versions: [],
        types: [],
        difficulties: [],
        minLevelValue: undefined,
        maxLevelValue: undefined,
        minBPM: undefined,
        maxBPM: undefined,
        artist: null,
        noteDesigners: [],
        region: undefined,
      },
    };
  },
  computed: {
    ...Pinia.mapState(useRootStore, [
      'filterOptions',
    ]),
  },
  watch: {
    filters: {
      handler() {
        this.$emit('update:filters', { ...this.filters });
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.filters = { ...this.filters, ...loadFiltersFromQuery(this.$route.query) };
    this.$router.replace({ query: {} }).catch(e => e);
  },
};
</script>
