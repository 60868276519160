<template>
  <v-footer>
    <v-spacer />
    <a href="https://www.free-counter.jp/" class="mr-2">
      <img :src="accessCounterUrl" alt="アクセスカウンター" width="70" height="12">
    </a>
    &copy; 2020 / made by zetaraku with &lt;3
  </v-footer>
</template>

<script>
export default {
  computed: {
    accessCounterUrl() {
      return process.env.VUE_APP_ACCESS_COUNTER_URL;
    },
  },
};
</script>
