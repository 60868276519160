/* eslint-disable camelcase */

const filterTypes = {
  categories: 'string[]',
  title: 'string',
  versions: 'string[]',
  types: 'string[]',
  difficulties: 'string[]',
  minLevelValue: 'number',
  maxLevelValue: 'number',
  minBPM: 'number',
  maxBPM: 'number',
  artist: 'string',
  noteDesigners: 'string[]',
  region: 'string',
};

let nonEmptyOrNull = arr => (arr.length !== 0 ? arr : null);

export function toLocalISOString(date) {
  let d = new Date(date.getTime());
  d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
  return d.toISOString().slice(0, -1);
}
export function getFormattedPercentage(n) {
  if (n === null || Number.isNaN(n)) return null;
  return `${Math.floor(10000 * n) / 100}%`;
}

export function buildFilterOptions({
  categories,
  titles,
  types,
  difficulties,
  levels,
  versions,
  artists,
  noteDesigners,
  regions,
  bpms,
}) {
  return {
    categories: nonEmptyOrNull(
      categories.map(({ category }) => ({
        text: category,
        value: category,
      })),
    ),
    titles: nonEmptyOrNull(
      titles,
    ),
    types: nonEmptyOrNull(
      types.map(({ type, name }) => ({
        text: name,
        value: type,
      })),
    ),
    difficulties: nonEmptyOrNull(
      difficulties.map(({ difficulty, name }) => ({
        text: name,
        value: difficulty,
      })),
    ),
    levels: nonEmptyOrNull(
      levels.map(({ level, levelValue }) => ({
        text: level,
        value: levelValue,
      })),
    ),
    versions: nonEmptyOrNull(
      versions.map(({ version, abbr }) => ({
        text: abbr,
        value: version,
      })),
    ),
    artists: nonEmptyOrNull(
      artists,
    ),
    noteDesigners: nonEmptyOrNull(
      noteDesigners.map(({ noteDesigner, sheetCount }) => ({
        text: `${noteDesigner} (${sheetCount})`,
        value: noteDesigner,
      })),
    ),
    regions$t: this$t => nonEmptyOrNull(
      regions.flatMap(({ region, name }) => [
        {
          text: name,
          value: `${region}`,
        },
        {
          text: this$t('tooltip.unavailableInRegion', { region: name }),
          value: `!${region}`,
        },
      ]),
    ),
    bpms: nonEmptyOrNull(
      bpms,
    ),
  };
}

export function makeDummySheet(sheetExpr) {
  let match = sheetExpr.split('|');

  // eslint-disable-next-line no-console
  console.warn(`Sheet for expr '${sheetExpr}' is not found.`);

  if (match.length !== 3) {
    return {
      songNo: 0,
      category: 'INVALID SHEET EXPR',
      title: sheetExpr,
      type: '??',
      difficulty: 'invalid',
      noteDesigner: 'This sheet expr is not in a valid format.',
      searchUrl: null,
    };
  }

  let [title, type, difficulty] = match;
  return {
    songNo: 0,
    category: 'UNMATCHED SHEET',
    title,
    type,
    difficulty,
    noteDesigner: 'This sheet expr doesn\'t match with any sheets.',
    searchUrl: null,
  };
}

export function getSheetExpr(sheet) {
  return `${sheet.songId}|${sheet.type}|${sheet.difficulty}`;
}

export function loadFiltersFromQuery(query) {
  const QueryReader = {
    string: str => str,
    number: str => Number(str),
    'string[]': str => str.split('|').map(QueryReader.string),
    'number[]': str => str.split('|').map(QueryReader.number),
  };

  let filters = {};

  for (let [key, name] of Object.entries(filterTypes)) {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      filters[key] = QueryReader[name](query[key]);
    }
  }

  return filters;

  // Get the query from:
  // this.$route.query;
}
export function saveFiltersAsQuery(filters) {
  const QueryWriter = {
    string: value => String(value),
    number: value => String(Number(value)),
    'string[]': values => values.map(QueryWriter.string).join('|'),
    'number[]': values => values.map(QueryWriter.number).join('|'),
  };

  let query = Object.create(null);

  for (let [key, name] of Object.entries(filterTypes)) {
    let value = filters[key];
    if (value == null || (Array.isArray(value) && value.length === 0)) continue;
    query[key] = QueryWriter[name](value);
  }

  return query;

  // To apply the query:
  // this.$router.replace({ query }).catch(err => {
  //   if (err.name !== 'NavigationDuplicated') {
  //     throw err; // Ignore the error regarding navigating to the page they are already on.
  //   }
  // });
}

export function filterSheets(sheets, filters) {
  let result = sheets.slice();

  if (filters === null) {
    return result;
  }

  if (filters.region != null) {
    if (filters.region.startsWith('!')) {
      let excludedRegion = filters.region.replace(/^!/, '');
      result = result.filter(sheet => !sheet.regions?.[excludedRegion] ?? false);
    } else {
      let includedRegion = filters.region;
      result = result.filter(sheet => sheet.regions?.[includedRegion] ?? false);
    }
  }
  if (filters.categories.length !== 0) {
    result = result.filter(sheet => filters.categories.some(
      category => sheet.category === category || (sheet.category?.split('|').includes(category) ?? false),
    ));
  }
  if (filters.title != null) {
    let normalizedTitle = filters.title.toLowerCase();
    result = result.filter(
      sheet => sheet.title?.toLowerCase().includes(normalizedTitle) ?? false,
    );
  }
  if (filters.versions.length !== 0) {
    result = result.filter(
      sheet => filters.versions.includes(sheet.version),
    );
  }
  if (filters.types.length !== 0) {
    result = result.filter(
      sheet => filters.types.includes(sheet.type),
    );
  }
  if (filters.difficulties.length !== 0) {
    result = result.filter(
      sheet => filters.difficulties.includes(sheet.difficulty),
    );
  }
  if (typeof filters.minLevelValue === 'number') {
    result = result.filter(
      sheet => sheet.levelValue != null && sheet.levelValue >= filters.minLevelValue,
    );
  }
  if (typeof filters.maxLevelValue === 'number') {
    result = result.filter(
      sheet => sheet.levelValue != null && sheet.levelValue <= filters.maxLevelValue,
    );
  }
  if (typeof filters.minBPM === 'number') {
    result = result.filter(
      sheet => sheet.bpm != null && sheet.bpm >= filters.minBPM,
    );
  }
  if (typeof filters.maxBPM === 'number') {
    result = result.filter(
      sheet => sheet.bpm != null && sheet.bpm <= filters.maxBPM,
    );
  }
  if (filters.artist != null) {
    let normalizedArtist = filters.artist.toLowerCase();
    result = result.filter(
      sheet => sheet.artist?.toLowerCase().includes(normalizedArtist) ?? false,
    );
  }
  if (filters.noteDesigners.length !== 0) {
    result = result.filter(
      sheet => filters.noteDesigners.includes(sheet.noteDesigner),
    );
  }

  return result;
}

export function shuffled(items, maxCount = items.length) {
  let itemsCopy = items.slice();

  for (let i = 0; i < itemsCopy.length; i += 1) {
    if (i + 1 > maxCount) break;

    let j = i + Math.floor((itemsCopy.length - i) * Math.random());
    [itemsCopy[i], itemsCopy[j]] = [itemsCopy[j], itemsCopy[i]];
  }

  return itemsCopy;
}
export function pickItem(items) {
  return items[Math.floor(items.length * Math.random())];
}
export function pickItems(items, count) {
  return [...Array(count)].map(() => pickItem(items));
}
export function pickUniqueItems(items, count) {
  return shuffled(items, count).slice(0, count);
}
