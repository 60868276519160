import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/songs',
    name: 'Songs',
    component: () => import('@/views/SongListView.vue'),
  },
  {
    path: '/songs/:songId',
    name: 'Song',
    component: () => import('@/views/SongView.vue'),
    props: true,
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import('@/views/GalleryView.vue'),
  },
  {
    path: '/portal',
    name: 'Portal',
    component: () => import('@/views/PortalView.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/AboutView.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@/views/NotFoundView.vue'),
  },
  {
    path: '*',
    redirect: { name: 'NotFound' },
  },
];

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
