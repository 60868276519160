<template>
  <v-app-bar app dark color="primary">
    <v-app-bar-nav-icon @click.stop="$emit('toggle-drawer')" />

    <router-link :to="{ name: 'Home' }">
      <div class="d-flex align-center">
        <v-icon large left right>
          mdi-music-box-multiple
        </v-icon>
        <v-sheet
          class="font-weight-medium transparent"
          :class="isSmartPhone ? 'text-h6' : 'text-h4'"
        >
          {{ appTitle }}
        </v-sheet>
      </div>
    </router-link>

    <v-spacer />

    <v-divider vertical inset class="mx-1" />

    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          text :small="isSmartPhone"
          :aria-label="$t('language')"
          v-bind="attrs" v-on="on"
        >
          <v-icon left>
            mdi-web
          </v-icon>
          <span>
            {{ localeLabel }}
          </span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="localeOption in localeOptions"
          :key="localeOption.locale"
          @click="changeLocale(localeOption.locale);"
        >
          <v-list-item-title>
            {{ localeOption.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import * as Pinia from 'pinia';
import useRootStore from '@/stores/root';

export default {
  inject: ['appTitle'],
  data() {
    return {
      localeOptions: [
        { locale: 'en', abbr: 'en', name: 'English' },
        { locale: 'ja', abbr: 'ja', name: '日本語' },
        { locale: 'zh-Hant', abbr: 'tc', name: '正體中文' },
        { locale: 'zh-Hans', abbr: 'sc', name: '简体中文' },
      ],
    };
  },
  computed: {
    ...Pinia.mapState(useRootStore, [
      'locale',
      'gallery',
      'secretFound',
    ]),
    localeLabel() {
      let option = this.localeOptions.find(e => e.locale === this.locale);

      if (option === undefined) return this.locale;

      return this.isSmartPhone ? option.abbr : option.name;
    },
    isSmartPhone() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    ...Pinia.mapActions(useRootStore, [
      'changeLocale',
    ]),
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "language": "Language"
  },
  "ja": {
    "language": "言語"
  },
  "zh-Hant": {
    "language": "語言"
  },
  "zh-Hans": {
    "language": "语言"
  }
}
</i18n>
