import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import {
  en, ja, zhHant, zhHans,
} from 'vuetify/lib/locale';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'md',
  },
  lang: {
    locales: {
      en,
      ja,
      'zh-Hant': zhHant,
      'zh-Hans': zhHans,
    },
    current: 'en',
  },
  theme: {
    themes: {
      light: {
        primary: process.env.VUE_APP_MAIN_THEME_COLOR,
      },
      dark: {
        primary: '#FFA31A',
      },
    },
  },
});
