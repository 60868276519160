<template>
  <v-container fluid>
    <LoadingOverlay v-if="isLoading" />

    <InfoBar />

    <SheetFilterPanel
      @update:filters="filters = $event;"
    />

    <ModeSelectorPanel
      :display-mode.sync="displayMode"
      :list-mode.sync="listMode"
      :filters="filters"
      :combo-drawer="comboDrawer"
      :selected-sheets.sync="selectedSheets"
    />

    <div class="text-center pb-5">
      <v-btn
        v-if="listMode !== 'combo'"
        large color="primary"
        class="text-h6 ma-3"
        @mousedown="onBtnPressStart" @mouseup="onBtnPressStop"
        @touchstart.prevent="onBtnPressStart" @touchend="onBtnPressStop"
      >
        {{ $t('drawRandomSheet') }}
      </v-btn>
      <v-btn
        v-if="listMode === 'combo'"
        large color="success"
        class="text-h6 ma-3"
        @mousedown="onBtnPressStart" @mouseup="onBtnPressStop"
        @touchstart.prevent="onBtnPressStart" @touchend="onBtnPressStop"
      >
        {{ $t('drawRandomCombo') }}
      </v-btn>
      <HelpButton
        :title="$t('drawSheetDescriptionTitle')"
        :descriptions="$t('drawSheetDescriptions')"
      />
    </div>

    <div class="text-center pt-2 pb-5">
      <v-progress-linear
        height="30" color="lime"
        :value="(displayingSheets.length / sheets.length) * 100"
        :aria-label="$t('filterResultCount')"
      >
        <span class="sans-serif text-truncate black--text">
          {{ displayingSheets.length }} / {{ $t('sheetsCount', { n: sheets.length }) }}
          ( {{ displayingSongsCount }} / {{ $t('songsCount', { n: songs.length }) }} )
        </span>
      </v-progress-linear>
    </div>

    <SheetDataView
      :display-mode="displayMode"
      :sheets="displayingSheets"
      :selected-sheets.sync="selectedSheets"
      @view-sheet="$emit('view-sheet', $event);"
    />
  </v-container>
</template>

<script>
import * as Pinia from 'pinia';
import useRootStore from '@/stores/root';
import vuetify from '@/plugins/vuetify';
import * as Utils from '@/utils';
import * as Consts from '@/utils/consts';
import ItemDrawer from '@/utils/ItemDrawer';
import SheetFilterPanel from '@/components/SheetFilterPanel.vue';
import SheetDataView from '@/components/SheetDataView.vue';
import InfoBar from '@/components/widgets/InfoBar.vue';
import HelpButton from '@/components/widgets/HelpButton.vue';
import LoadingOverlay from '@/components/widgets/LoadingOverlay.vue';
import ModeSelectorPanel from '@/components/ModeSelectorPanel.vue';

const btnThreshold = 2000;

export default {
  components: {
    InfoBar,
    SheetFilterPanel,
    SheetDataView,
    HelpButton,
    LoadingOverlay,
    ModeSelectorPanel,
  },
  data() {
    return {
      filters: null,
      selectedSheets: [],

      comboDrawer: new ItemDrawer({ drawSize: 4, defaultItem: Consts.UNKNOWN_SHEET }),

      displayMode: 'grid',
      listMode: 'filter',

      btnPressTime: -1,
      btnPressInterval: null,
    };
  },
  computed: {
    ...Pinia.mapState(useRootStore, [
      'songs',
      'sheets',
      'sheetExprMapping',
      'filterOptions',
      'isLoading',
    ]),
    displayingSongsCount() {
      return new Set(this.displayingSheets.map(sheet => sheet.songNo)).size;
    },
    displayingSheets() {
      if (this.listMode === 'filter') return this.filteredSheets;
      if (this.listMode === 'combo') return this.comboSheets;
      if (this.listMode === 'mylist') return this.selectedSheets;

      return [];
    },
    filteredSheets() {
      return Utils.filterSheets(this.sheets, this.filters);
    },
    comboSheets() {
      return this.comboDrawer.currentItems;
    },
  },
  methods: {
    onBtnPressStart() {
      if (this.btnPressInterval !== null) {
        clearInterval(this.btnPressInterval);
        this.btnPressInterval = null;
      }

      const tick = 100;
      this.btnPressTime = 0;
      this.btnPressInterval = setInterval(() => {
        this.btnPressTime += tick;
        if (this.btnPressTime >= btnThreshold) {
          this.stopBtnPress();
          this.switchMode();
        }
      }, tick);
    },
    onBtnPressStop() {
      if (this.btnPressTime !== -1) {
        this.stopBtnPress();

        if (this.listMode === 'combo') {
          this.drawCombo();
        } else {
          this.$emit('draw-sheet', this.displayingSheets);
        }
      }
    },
    stopBtnPress() {
      if (this.btnPressInterval !== null) {
        clearInterval(this.btnPressInterval);
        this.btnPressInterval = null;
      }
      this.btnPressTime = -1;
    },
    switchMode() {
      let rootStore = useRootStore();

      rootStore.secretFound = !rootStore.secretFound;
      vuetify.framework.theme.dark = rootStore.secretFound;

      if (rootStore.secretFound) {
        this.$emit('view-sheet', Consts.SECRET_SHEET);
        this.$gtag.event('EasterEggFound');
      }
    },
    drawCombo() {
      document.getElementById('drawComboAnchor')?.scrollIntoView({
        behavior: 'smooth',
      });

      this.comboDrawer.setDrawingPool(this.filteredSheets);
      this.comboDrawer.start()
        .then(isFinished => {
          if (isFinished) {
            this.$gtag.event('RandomComboDrawn', { value: 1 });
          }
        });
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "drawSheetDescriptionTitle": "Instruction",
    "drawSheetDescriptions": [
      "The sheet will be drawn from all sheets displayed below.",
      "You can apply some filters first to narrow down the pool.",
      "If you want to draw from a custom pool, you can first add",
      "the sheets to MY LIST, and then you can draw in MY LIST."
    ],
    "drawRandomSheet": "DRAW RANDOM SHEET!",
    "drawRandomCombo": "DRAW RANDOM SET!",
    "sheetsCount": "{n} sheets",
    "songsCount": "{n} songs",
    "filterResultCount": "Filter result count"
  },
  "ja": {
    "drawSheetDescriptionTitle": "使用說明",
    "drawSheetDescriptions": [
      "譜面は、下に表示されているの譜面から抽選されます。",
      "フィルターを適用して、譜面を絞り込むことができます。",
      "カスタムプールから抽選したい場合、まず譜面を「マイリスト」に追加して、「マイリスト」で抽選してください。"
    ],
    "drawRandomSheet": "譜面を抽選する！",
    "drawRandomCombo": "譜面セットを抽選する！",
    "sheetsCount": "{n} 譜面",
    "songsCount": "{n} 曲",
    "filterResultCount": "フィルター結果数"
  },
  "zh-Hant": {
    "drawSheetDescriptionTitle": "使用說明",
    "drawSheetDescriptions": [
      "譜面抽取的範圍為以下列出的所有譜面，你可以指定篩選條件來限定譜面抽取的範圍。",
      "如果想從自選的範圍抽取，你可以先將譜面加入「我的歌單」之後，再切換到「我的歌單」進行抽取。"
    ],
    "drawRandomSheet": "隨機抽取譜面！",
    "drawRandomCombo": "隨機抽取組曲！",
    "sheetsCount": "{n} 譜面",
    "songsCount": "{n} 歌曲",
    "filterResultCount": "篩選結果數"
  },
  "zh-Hans": {
    "drawSheetDescriptionTitle": "使用说明",
    "drawSheetDescriptions": [
      "谱面抽取的范围为以下列出的所有谱面，你可以指定筛选条件来限定谱面抽取的范围。",
      "如果想从自选的范围抽取，你可以先将谱面加入「我的歌单」之后，再切换到「我的歌单」进行抽取。"
    ],
    "drawRandomSheet": "随机抽取谱面！",
    "drawRandomCombo": "随机抽取组曲！",
    "sheetsCount": "{n} 谱面",
    "songsCount": "{n} 乐曲",
    "filterResultCount": "筛选结果数"
  }
}
</i18n>
