var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"SheetTile rounded pa-3 ma-1",on:{"dragstart":function($event){$event.preventDefault();},"contextmenu":function($event){$event.preventDefault();}}},_vm.$listeners),[_c('v-tooltip',{attrs:{"top":"","nudge-bottom":80,"disabled":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"SpriteBackground d-inline-block pa-2",class:{ 'rainbow-background': _vm.sheet.isSpecial }},on),[_c('div',{staticClass:"SpriteContainer grey",class:{ 'dark-style': _vm.$vuetify.theme.dark },staticStyle:{"position":"relative"}},[_c('div',{style:({
              'position': 'absolute',
              'top': 0,
              'left': 0,
              'width': "100px",
              'height': "100px",
            })}),_c('img',{staticStyle:{"object-fit":"cover","vertical-align":"middle"},attrs:{"src":_vm.sheet.imageUrl,"width":"100","height":"100","alt":""}}),(_vm.sheet.imageName === 'default-cover.png')?_c('span',{staticClass:"SpriteText"},[_vm._v(" "+_vm._s(_vm.sheet.title)+" ")]):_vm._e(),(_vm.sheet.isLocked)?_c('img',{staticStyle:{"position":"absolute","top":"0","right":"0","transform":"translate(+50%, -50%)","vertical-align":"middle"},attrs:{"src":_vm.lockedIconUrl,"height":_vm.lockedIconHeight,"alt":""}}):_vm._e(),_c('img',{staticStyle:{"position":"absolute","bottom":"0","left":"-10px","transform":"translate(0%, +50%)","vertical-align":"middle"},attrs:{"src":_vm.getTypeIconUrl(_vm.sheet.type),"height":_vm.getTypeIconHeight(_vm.sheet.type),"alt":""}}),(_vm.sheet.internalLevel != null)?_c('span',{staticClass:"SheetInternalLevel",domProps:{"textContent":_vm._s(_vm.sheet.internalLevel)}}):_vm._e()])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.sheet.title))])]),_c('div',{staticClass:"mt-4",staticStyle:{"width":"116px","text-align":"center"}},[_c('div',{staticClass:"text-no-wrap"},[_c('span',{style:({
          'color': _vm.getDifficultyColor(_vm.sheet.difficulty),
          'font-size': _vm.sheet.levelValue >= 100 ? 'small' : '',
          'font-weight': 'bold',
        })},[(_vm.getDifficultyIconUrl(_vm.sheet.difficulty) != null)?_c('span',[_c('img',{staticClass:"mr-1",staticStyle:{"vertical-align":"middle"},attrs:{"src":_vm.getDifficultyIconUrl(_vm.sheet.difficulty),"width":"25","height":"25","alt":((_vm.$t('key.difficulty')) + ": " + (_vm.sheet.difficulty))}})]):_c('span',[_vm._v(" "+_vm._s(_vm.getDifficultyName(_vm.sheet.difficulty))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.sheet.level)+" ")])])]),_c('div',{staticClass:"text-pre-wrap"},[_vm._t("description")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }