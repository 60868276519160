<template>
  <div>
    <SheetDataGrid
      v-if="displayMode === 'grid'"
      :sheets="sheets"
      :selected-sheets.sync="selectedSheetsProxy"
      @view-sheet="$emit('view-sheet', $event);"
    />
    <SheetDataTable
      v-else-if="displayMode === 'table'"
      :sheets="sheets"
      :selected-sheets.sync="selectedSheetsProxy"
      @view-sheet="$emit('view-sheet', $event);"
    />
  </div>
</template>

<script>
import SheetDataGrid from './SheetDataGrid.vue';
import SheetDataTable from './SheetDataTable.vue';

export default {
  components: {
    SheetDataGrid,
    SheetDataTable,
  },
  props: {
    sheets: {
      type: Array,
      required: true,
    },
    selectedSheets: {
      type: Array,
      required: true,
    },
    displayMode: {
      type: String,
      required: true,
    },
  },
  computed: {
    selectedSheetsProxy: {
      get() {
        return this.selectedSheets;
      },
      set(value) {
        this.$emit('update:selected-sheets', value);
      },
    },
  },
};
</script>
