<template>
  <v-app>
    <AppHeader
      @toggle-drawer="isNavDrawerOpened = !isNavDrawerOpened"
    />

    <v-navigation-drawer
      v-model="isNavDrawerOpened"
      app
      temporary
    >
      <v-list-item>
        <v-icon large left color="primary">
          mdi-music-box-multiple
        </v-icon>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ appTitle }}
          </v-list-item-title>
          <v-list-item-subtitle>
            made by @zetaraku
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list nav>
        <v-list-item
          v-for="item in navMenuItems" :key="item.title"
          :to="item.to" exact
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid fill-height>
        <keep-alive>
          <router-view
            @draw-sheet="drawRandomSheetFrom($event);"
            @view-sheet="viewSheet($event);"
          />
        </keep-alive>
      </v-container>
    </v-main>

    <SheetDialog
      :opened.sync="sheetDialogOpened"
      :sheet="sheetDrawer.currentItems[0]"
      :is-draw-mode="isDrawMode"
      :is-static="!sheetDrawer.isDrawing"
      @redraw-sheet="startDrawingRandomSheet"
    />

    <VersionUpdateDialog />

    <AppFooter />
  </v-app>
</template>

<script>
import * as Pinia from 'pinia';
import useRootStore from '@/stores/root';
import SheetDialog from '@/components/dialogs/SheetDialog.vue';
import VersionUpdateDialog from '@/components/dialogs/VersionUpdateDialog.vue';
import * as Consts from '@/utils/consts';
import ItemDrawer from '@/utils/ItemDrawer';
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';

export default {
  components: {
    AppHeader,
    AppFooter,
    SheetDialog,
    VersionUpdateDialog,
  },
  metaInfo() {
    return {
      title: this.$route.name,
      titleTemplate: `%s | ${process.env.VUE_APP_TITLE}`,
    };
  },
  provide: {
    gameCode: process.env.VUE_APP_GAME_CODE,
    gameTitle: process.env.VUE_APP_GAME_TITLE,
    appTitle: process.env.VUE_APP_TITLE,
  },
  data() {
    return {
      appTitle: process.env.VUE_APP_TITLE,
      isNavDrawerOpened: null,
      sheetDrawer: new ItemDrawer({ drawSize: 1, defaultItem: Consts.NULL_SHEET }),
      isDrawMode: undefined,
      sheetDialogOpened: false,
    };
  },
  computed: {
    ...Pinia.mapState(useRootStore, [
      'gallery',
      'isLoading',
    ]),
    navMenuItems() {
      return [
        {
          title: this.$t('pages.home'),
          icon: 'mdi-music-box-multiple',
          to: { name: 'Home' },
        },
        {
          title: this.$t('pages.songs'),
          icon: 'mdi-database',
          to: { name: 'Songs' },
        },
        ...(
          this.gallery !== null ? [{
            title: this.$t('pages.gallery'),
            icon: 'mdi-script-text',
            to: { name: 'Gallery' },
          }] : []
        ),
        {
          title: this.$t('pages.portal'),
          icon: 'mdi-near-me',
          to: { name: 'Portal' },
        },
        {
          title: this.$t('pages.about'),
          icon: 'mdi-information-outline',
          to: { name: 'About' },
        },
      ];
    },
  },
  watch: {
    sheetDialogOpened(opened) {
      if (!opened) this.sheetDrawer.stop();
    },
  },
  async created() {
    let rootStore = useRootStore();
    await rootStore.detectLocale();
    await rootStore.fetchData();
  },
  methods: {
    openSheetDialog({ isDrawMode = false } = {}) {
      this.isDrawMode = isDrawMode;
      this.sheetDialogOpened = true;
    },
    drawRandomSheetFrom(sheets) {
      this.sheetDrawer.setDrawingPool(sheets);

      this.startDrawingRandomSheet();
    },
    async startDrawingRandomSheet() {
      this.openSheetDialog({ isDrawMode: true });

      let isFinished = await this.sheetDrawer.start();

      if (isFinished) {
        this.$gtag.event('RandomSongDrawn', { value: 1 });
      }
    },
    viewSheet(sheet) {
      this.sheetDrawer.setStaticItems([sheet]);

      this.openSheetDialog({ isDrawMode: false });

      this.$gtag.event('SheetViewed', { value: 1 });
    },
  },
};
</script>
