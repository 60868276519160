<template>
  <div
    v-ripple
    class="SheetTile rounded pa-3 ma-1"
    v-on="$listeners"
    @dragstart.prevent
    @contextmenu.prevent
  >
    <v-tooltip top :nudge-bottom="80" :disabled="$vuetify.breakpoint.mobile">
      <template #activator="{ on }">
        <div
          class="SpriteBackground d-inline-block pa-2"
          :class="{ 'rainbow-background': sheet.isSpecial }"
          v-on="on"
        >
          <div
            class="SpriteContainer grey"
            :class="{ 'dark-style': $vuetify.theme.dark }"
            style="position: relative;"
          >
            <!-- invisible blocking panel (prevent long-press and right-click) -->
            <div
              :style="{
                'position': 'absolute',
                'top': 0,
                'left': 0,
                'width': `100px`,
                'height': `100px`,
              }"
            />

            <!-- sheet cover image -->
            <img
              :src="sheet.imageUrl"
              width="100" height="100"
              style="object-fit: cover; vertical-align: middle;"
              alt=""
            >

            <!-- sheet title (if cover is default) -->
            <span
              v-if="sheet.imageName === 'default-cover.png'"
              class="SpriteText"
            >
              {{ sheet.title }}
            </span>

            <!-- locked icon -->
            <img
              v-if="sheet.isLocked"
              :src="lockedIconUrl"
              :height="lockedIconHeight"
              style="
                position: absolute;
                top: 0; right: 0;
                transform: translate(+50%, -50%);
                vertical-align: middle;
              "
              alt=""
            >

            <!-- type icon -->
            <img
              :src="getTypeIconUrl(sheet.type)"
              :height="getTypeIconHeight(sheet.type)"
              style="
                position: absolute;
                bottom: 0; left: -10px;
                transform: translate(0%, +50%);
                vertical-align: middle;
              "
              alt=""
            >

            <!-- sheet internal level -->
            <span
              v-if="sheet.internalLevel != null"
              class="SheetInternalLevel"
              v-text="sheet.internalLevel"
            />
          </div>
        </div>
      </template>
      <span>{{ sheet.title }}</span>
    </v-tooltip>

    <div
      class="mt-4"
      style="width: 116px; text-align: center;"
    >
      <div class="text-no-wrap">
        <span
          :style="{
            'color': getDifficultyColor(sheet.difficulty),
            'font-size': sheet.levelValue >= 100 ? 'small' : '',
            'font-weight': 'bold',
          }"
        >
          <!-- sheet difficulty -->
          <span v-if="getDifficultyIconUrl(sheet.difficulty) != null">
            <img
              :src="getDifficultyIconUrl(sheet.difficulty)"
              width="25" height="25"
              class="mr-1"
              style="vertical-align: middle;"
              :alt="`${$t('key.difficulty')}: ${sheet.difficulty}`"
            >
          </span>
          <span v-else>
            {{ getDifficultyName(sheet.difficulty) }}
          </span>

          <!-- sheet level -->
          <span>
            {{ sheet.level }}
          </span>
        </span>
      </div>
      <div class="text-pre-wrap">
        <slot name="description" />
      </div>
    </div>
  </div>
</template>

<script>
import * as Pinia from 'pinia';
import useRootStore from '@/stores/root';

export default {
  inject: ['gameCode'],
  props: {
    sheet: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...Pinia.mapState(useRootStore, [
      'lockedIconUrl',
      'lockedIconHeight',
      'getTypeIconUrl',
      'getTypeIconHeight',
      'getDifficultyIconUrl',
      'getDifficultyName',
      'getDifficultyColor',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.SheetTile {
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 5px rgb(0, 0, 0, 0.25);

    .SpriteContainer {
      transform: scale(1.2);
    }
  }

  .SpriteContainer {
    display: inline-block;
    user-select: none;
    box-shadow:
      // the gray shadows
      0px 14px 28px rgba(0, 0, 0, 0.25),
      0px 10px 10px rgba(0, 0, 0, 0.22);
    transition: transform 250ms;

    &.dark-style {
      box-shadow:
        // the yellow glowing effect
        0px 14px 28px 5px rgba(255, 255, 0, 0.50),
        // the white glowing square
        0px 0px 0px 10px rgba(255, 255, 255, 0.22);
    }

    .SpriteText {
      position: absolute;

      top: 0;
      left: 0;
      padding: 5px;

      max-width: 100%;
      max-height: 100%;

      font-weight: bold;
      color: white;

      -webkit-text-stroke: 5px black;
      paint-order: stroke;

      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .SheetInternalLevel {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 3px;
      min-width: 40px;
      border-radius: 3px;
      transform: translate(-50%, -50%);

      font-weight: bold;
      text-align: center;
      color: white;
      background-color: gray;
    }
  }
}
</style>
