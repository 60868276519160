<template>
  <div>
    <v-container fluid>
      <v-row v-if="gameCode === 'maimai'">
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-alert
            outlined text dense color="primary" class="px-6 py-0"
            style="cursor: pointer;"
            @click="showNotesInfo = !showNotesInfo;"
          >
            <v-checkbox
              v-model="showNotesInfo"
              :label="$t('showNotesInfo')"
              readonly
            />
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :value="selectedSheets"
      :headers="headers"
      :items="sheets"
      :page.sync="currentPage"
      item-key="sheetNo"
      must-sort
      show-select
      @input="$emit('update:selected-sheets', $event);"
    >
      <template #item.songNo="{ item }">
        {{ item.songNo }}
      </template>
      <template #item.category="{ item }">
        {{ (item.category || '').replaceAll('|', '｜') }}
      </template>
      <template #item.title="{ item }">
        <v-btn icon color="cyan" @click="$emit('view-sheet', item);">
          <v-icon>mdi-file-image</v-icon>
        </v-btn>
        <span>
          {{ item.title }}
        </span>
        <v-tooltip v-if="item.isLocked" top>
          <template #activator="{ on }">
            <v-icon right color="yellow darken-2" v-on="on">
              mdi-lock
            </v-icon>
          </template>
          <span>{{ $t('tooltip.unlockNeeded') }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.isNew" top>
          <template #activator="{ on }">
            <v-icon right color="yellow darken-2" v-on="on">
              mdi-star
            </v-icon>
          </template>
          <span>{{ $t('tooltip.newSong') }}</span>
        </v-tooltip>
      </template>
      <template #item.type="{ item }">
        {{ getTypeAbbr(item.type) }}
      </template>
      <template #item.difficulty="{ item }">
        <div
          class="font-weight-bold"
          :style="{ 'color': getDifficultyColor(item.difficulty) }"
        >
          <span v-if="getDifficultyIconUrl(item.difficulty) != null">
            <img
              :src="getDifficultyIconUrl(item.difficulty)"
              width="25" height="25"
              class="mr-1"
              style="vertical-align: middle;"
              :alt="`${$t('key.difficulty')}: ${item.difficulty}`"
            >
          </span>
          <span>
            {{ getDifficultyName(item.difficulty) }}
          </span>
        </div>
      </template>
      <template #item.levelValue="{ item }">
        <div class="font-weight-bold">
          <span :style="{ 'color': getDifficultyColor(item.difficulty) }">
            {{ item.level }}
          </span>
        </div>
      </template>
      <template #item.notePercents.tap="{ item }">
        {{ item.notePercents ? getFormattedPercentage(item.notePercents.tap) : null }}
      </template>
      <template #item.notePercents.hold="{ item }">
        {{ item.notePercents ? getFormattedPercentage(item.notePercents.hold) : null }}
      </template>
      <template #item.notePercents.slide="{ item }">
        {{ item.notePercents ? getFormattedPercentage(item.notePercents.slide) : null }}
      </template>
      <template #item.notePercents.touch="{ item }">
        {{ item.notePercents ? getFormattedPercentage(item.notePercents.touch) : null }}
      </template>
      <template #item.notePercents.break="{ item }">
        {{ item.notePercents ? getFormattedPercentage(item.notePercents.break) : null }}
      </template>
      <template #item.artist="{ item }">
        {{ item.artist }}
      </template>
      <template #item.noteDesigner="{ item }">
        {{ item.noteDesigner }}
      </template>
      <template #item.version="{ item }">
        {{ getVersionAbbr(item.version) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as Pinia from 'pinia';
import useRootStore from '@/stores/root';
import { getFormattedPercentage } from '@/utils';

export default {
  inject: ['gameCode'],
  props: {
    sheets: {
      type: Array,
      required: true,
    },
    selectedSheets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      showNotesInfo: true,
    };
  },
  computed: {
    ...Pinia.mapState(useRootStore, [
      'songs',
      'filterOptions',
      'getTypeAbbr',
      'getDifficultyIconUrl',
      'getDifficultyName',
      'getDifficultyColor',
      'difficultyOrders',
      'getVersionAbbr',
      'versionOrders',
    ]),
    headers() {
      const notesInfo = {
        maimai: [
          {
            text: 'TAP',
            value: 'noteCounts.tap',
            width: 50,
          },
          {
            text: 'TAP %',
            value: 'notePercents.tap',
            width: 50,
          },
          {
            text: 'HOLD',
            value: 'noteCounts.hold',
            width: 50,
          },
          {
            text: 'HOLD %',
            value: 'notePercents.hold',
            width: 50,
          },
          {
            text: 'SLIDE',
            value: 'noteCounts.slide',
            width: 50,
          },
          {
            text: 'SLIDE %',
            value: 'notePercents.slide',
            width: 50,
          },
          {
            text: 'TOUCH',
            value: 'noteCounts.touch',
            width: 50,
          },
          {
            text: 'TOUCH %',
            value: 'notePercents.touch',
            width: 50,
          },
          {
            text: 'BREAK',
            value: 'noteCounts.break',
            width: 50,
          },
          {
            text: 'BREAK %',
            value: 'notePercents.break',
            width: 50,
          },
          {
            text: this.$t('key.totalNotes'),
            value: 'noteCounts.total',
            width: 50,
          },
        ],
      };

      return [
        {
          text: 'No.',
          value: 'songNo',
          width: 10,
        },
        {
          text: this.$t('key.category'),
          value: 'category',
          width: 200,
        },
        {
          text: this.$t('key.title'),
          value: 'title',
          width: 300,
        },
        {
          text: this.$t('key.type'),
          value: 'type',
          width: 75,
        },
        {
          text: this.$t('key.difficulty'),
          value: 'difficulty',
          width: 100,
          sort: (a, b) => (this.difficultyOrders[a] || 999) - (this.difficultyOrders[b] || 999),
        },
        {
          text: this.$t('key.level'),
          value: 'levelValue',
          width: 100,
        },
        {
          text: this.$t('key.internalLevel'),
          value: 'internalLevelValue',
          width: 100,
        },
        ...(this.showNotesInfo ? notesInfo[this.gameCode] ?? [] : []),
        {
          text: 'BPM',
          value: 'bpm',
          width: 50,
        },
        // {
        //   text: this.$t('key.artist'),
        //   value: 'artist',
        //   width: 150,
        // },
        {
          text: this.$t('key.noteDesigner'),
          value: 'noteDesigner',
          width: 150,
        },
        {
          text: this.$t('key.version'),
          value: 'version',
          width: 150,
          sort: (a, b) => this.versionOrders[a] - this.versionOrders[b],
        },
      ];
    },
  },
  watch: {
    sheets() {
      this.currentPage = 1;
    },
  },
  methods: {
    getFormattedPercentage,
  },
};
</script>

<style lang="scss" scoped>
.note-count-mismatched {
  color: red;
  font-weight: bold;
}
</style>

<i18n lang="json">
{
  "en": {
    "showNotesInfo": "Show NOTE Counts"
  },
  "ja": {
    "showNotesInfo": "ノートの内訳を表示する"
  },
  "zh-Hant": {
    "showNotesInfo": "顯示 NOTE 數量資訊"
  },
  "zh-Hans": {
    "showNotesInfo": "显示 NOTE 数量信息"
  }
}
</i18n>
