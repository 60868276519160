var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[(_vm.gameCode === 'maimai')?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"px-6 py-0",staticStyle:{"cursor":"pointer"},attrs:{"outlined":"","text":"","dense":"","color":"primary"},on:{"click":function($event){_vm.showNotesInfo = !_vm.showNotesInfo;}}},[_c('v-checkbox',{attrs:{"label":_vm.$t('showNotesInfo'),"readonly":""},model:{value:(_vm.showNotesInfo),callback:function ($$v) {_vm.showNotesInfo=$$v},expression:"showNotesInfo"}})],1)],1)],1):_vm._e()],1),_c('v-data-table',{attrs:{"value":_vm.selectedSheets,"headers":_vm.headers,"items":_vm.sheets,"page":_vm.currentPage,"item-key":"sheetNo","must-sort":"","show-select":""},on:{"update:page":function($event){_vm.currentPage=$event},"input":function($event){return _vm.$emit('update:selected-sheets', $event);}},scopedSlots:_vm._u([{key:"item.songNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.songNo)+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.category || '').replaceAll('|', '｜'))+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"cyan"},on:{"click":function($event){return _vm.$emit('view-sheet', item);}}},[_c('v-icon',[_vm._v("mdi-file-image")])],1),_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]),(item.isLocked)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"right":"","color":"yellow darken-2"}},on),[_vm._v(" mdi-lock ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.unlockNeeded')))])]):_vm._e(),(item.isNew)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"right":"","color":"yellow darken-2"}},on),[_vm._v(" mdi-star ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.newSong')))])]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeAbbr(item.type))+" ")]}},{key:"item.difficulty",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:({ 'color': _vm.getDifficultyColor(item.difficulty) })},[(_vm.getDifficultyIconUrl(item.difficulty) != null)?_c('span',[_c('img',{staticClass:"mr-1",staticStyle:{"vertical-align":"middle"},attrs:{"src":_vm.getDifficultyIconUrl(item.difficulty),"width":"25","height":"25","alt":((_vm.$t('key.difficulty')) + ": " + (item.difficulty))}})]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.getDifficultyName(item.difficulty))+" ")])])]}},{key:"item.levelValue",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_c('span',{style:({ 'color': _vm.getDifficultyColor(item.difficulty) })},[_vm._v(" "+_vm._s(item.level)+" ")])])]}},{key:"item.notePercents.tap",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.notePercents ? _vm.getFormattedPercentage(item.notePercents.tap) : null)+" ")]}},{key:"item.notePercents.hold",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.notePercents ? _vm.getFormattedPercentage(item.notePercents.hold) : null)+" ")]}},{key:"item.notePercents.slide",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.notePercents ? _vm.getFormattedPercentage(item.notePercents.slide) : null)+" ")]}},{key:"item.notePercents.touch",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.notePercents ? _vm.getFormattedPercentage(item.notePercents.touch) : null)+" ")]}},{key:"item.notePercents.break",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.notePercents ? _vm.getFormattedPercentage(item.notePercents.break) : null)+" ")]}},{key:"item.artist",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.artist)+" ")]}},{key:"item.noteDesigner",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.noteDesigner)+" ")]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getVersionAbbr(item.version))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }