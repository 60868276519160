import * as Pinia from 'pinia';
import axios from 'axios';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import * as Utils from '@/utils';

const gameCode = process.env.VUE_APP_GAME_CODE;
const gameTitle = process.env.VUE_APP_GAME_TITLE;

const REMOTE_DATA_ADDR = process.env.VUE_APP_REMOTE_DATA_ADDR;
const DATA_SOURCE_URL = `${REMOTE_DATA_ADDR}/${gameCode}`;

export default Pinia.defineStore('root', {
  state: () => ({
    locale: 'en',

    songs: [],
    sheets: [],
    sheetExprMapping: new Map(),

    categories: [],
    titles: [],
    types: [],
    difficulties: [],
    levels: [],
    versions: [],
    artists: [],
    noteDesigners: [],
    regions: [],
    bpms: [],

    filterOptions: {
      categories: [],
      titles: [],
      types: [],
      difficulties: [],
      levels: [],
      versions: [],
      artists: [],
      noteDesigners: [],
      regions$t: () => [],
      bpms: [],
    },

    gallery: null,

    dataTime: null,
    errorMessage: null,
    isLoading: false,
    isLoaded: false,
    secretFound: false,
  }),
  actions: {
    async fetchData() {
      if (this.isLoaded) return;

      this.isLoading = true;
      try {
        let { data } = await axios.get(`${DATA_SOURCE_URL}/data.json`);
        await this.loadData(data);
        await this.fetchGallery();
        this.isLoaded = true;
      } catch (err) {
        this.errorMessage = err;
      } finally {
        this.isLoading = false;
      }
    },
    async loadData(data) {
      const {
        categories,
        types,
        difficulties,
        versions,
        regions,
        updateTime,
      } = data;

      function resolveUrl(filePath, baseUrl) {
        return filePath != null ? new URL(filePath, baseUrl).toString() : filePath;
      }
      function computeNotePercentages(noteCounts) {
        return noteCounts != null ? Object.fromEntries(
          Object.entries(noteCounts)
            .map(([key, value]) => [key, Number(value) / noteCounts.total]),
        ) : noteCounts;
      }

      let lastSongNo = 0;
      let lastSheetNo = 0;
      for (const song of data.songs) {
        lastSongNo += 1;
        song.songNo = lastSongNo;
        song.imageUrl = resolveUrl(song.imageName, `${DATA_SOURCE_URL}/img/cover/`);

        for (const sheet of song.sheets) {
          Object.setPrototypeOf(sheet, song);

          lastSheetNo += 1;
          sheet.sheetNo = lastSheetNo;
          sheet.notePercents = computeNotePercentages(sheet.noteCounts);
        }
      }

      const songs = data.songs.slice().reverse();
      const sheets = songs.flatMap(song => song.sheets);

      let sheetExprMapping = new Map(sheets.map(sheet => [Utils.getSheetExpr(sheet), sheet]));

      let titles = [...new Set(songs.map(song => song.title))];
      let artists = [...new Set(songs.map(song => song.artist))]
        .filter(artist => artist != null);
      let noteDesigners = [...new Set(sheets.map(sheet => sheet.noteDesigner))]
        .map(noteDesigner => ({
          noteDesigner,
          sheetCount: sheets.filter(
            sheet => sheet.noteDesigner === noteDesigner,
          ).length,
        }))
        .filter(({ noteDesigner }) => noteDesigner != null)
        .sort((a, b) => b.sheetCount - a.sheetCount);
      let bpms = [...new Set(songs.map(song => song.bpm))]
        .filter(bpm => bpm != null)
        .sort((a, b) => a - b);
      let levels = [...new Map(sheets.map(sheet => [sheet.levelValue, sheet.level])).entries()]
        .filter(([levelValue, level]) => levelValue != null && level != null)
        .sort(([aLevelValue], [bLevelValue]) => aLevelValue - bLevelValue)
        .map(([levelValue, level]) => ({ level, levelValue }));

      for (const type of types) {
        type.iconUrl = resolveUrl(type.iconUrl, `${DATA_SOURCE_URL}/img/`);
      }

      for (const difficulty of difficulties) {
        difficulty.iconUrl = resolveUrl(difficulty.iconUrl, `${DATA_SOURCE_URL}/img/`);
      }

      this.songs = songs;
      this.sheets = sheets;

      this.sheetExprMapping = sheetExprMapping;

      this.categories = categories;
      this.titles = titles;
      this.types = types;
      this.difficulties = difficulties;
      this.levels = levels;
      this.versions = versions;
      this.artists = artists;
      this.noteDesigners = noteDesigners;
      this.regions = regions;
      this.bpms = bpms;

      this.filterOptions = Utils.buildFilterOptions({
        categories,
        titles,
        types,
        difficulties,
        levels,
        versions,
        artists,
        noteDesigners,
        regions,
        bpms,
      });

      this.dataTime = updateTime;
    },
    async fetchGallery() {
      try {
        let { data: gallery } = await axios.get(`${DATA_SOURCE_URL}/gallery.json`);
        await this.loadGallery(gallery);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('The gallery is currently unavailable.');
      }
    },
    async loadGallery(gallery) {
      let pages = gallery.map(page => ({
        ...page,
        sections: page.sections.map(list => ({
          ...list,
          sheets: list.sheets.map(
            sheetExpr => this.sheetExprMapping.get(sheetExpr) ?? Utils.makeDummySheet(sheetExpr),
          ),
        })),
      }));
      this.gallery = pages;
    },
    async changeLocale(locale) {
      this.locale = locale;
      i18n.locale = locale;
      vuetify.framework.lang.current = locale;
      document.querySelector('html').setAttribute('lang', locale);
      window.localStorage.setItem('locale', locale);
    },
    async detectLocale() {
      const availableLocales = [
        'en',
        'ja',
        'zh-Hant',
        'zh-Hans',
      ];
      const localeMapping = {
        'zh-TW': 'zh-Hant',
        'zh-CN': 'zh-Hans',
      };

      let savedLocale = window.localStorage.getItem('locale');
      let browserLocale = localeMapping[navigator.language] ?? navigator.language;
      let defaultLocale = 'en';

      if (availableLocales.includes(savedLocale)) {
        this.changeLocale(savedLocale);
      } else if (availableLocales.includes(browserLocale)) {
        this.changeLocale(browserLocale);
      } else {
        this.changeLocale(defaultLocale);
      }
    },
  },
  getters: {
    lockedIconUrl() {
      return new URL('locked.png', `${DATA_SOURCE_URL}/img/`).toString();
    },
    lockedIconHeight() {
      return 40;
    },
    getTypeAbbr() {
      return targetType => this.types.find(
        ({ type }) => type === targetType,
      )?.abbr ?? String(targetType).toUpperCase();
    },
    getTypeIconUrl() {
      return targetType => this.types.find(
        ({ type }) => type === targetType,
      )?.iconUrl ?? null;
    },
    getTypeIconHeight() {
      return targetType => this.types.find(
        ({ type }) => type === targetType,
      )?.iconHeight ?? null;
    },
    difficultyOrders() {
      return this.difficulties.reduce((acc, e, i) => {
        acc[e.difficulty] = 1 + i;
        return acc;
      }, {});
    },
    getDifficultyIconUrl() {
      return targetDifficulty => this.difficulties.find(
        ({ difficulty }) => difficulty === targetDifficulty,
      )?.iconUrl ?? null;
    },
    getDifficultyName() {
      return targetDifficulty => this.difficulties.find(
        ({ difficulty }) => difficulty === targetDifficulty,
      )?.name ?? String(targetDifficulty).toUpperCase();
    },
    getDifficultyColor() {
      return targetDifficulty => this.difficulties.find(
        ({ difficulty }) => difficulty === targetDifficulty,
      )?.color ?? 'unset';
    },
    versionOrders() {
      return this.versions.reduce((acc, e, i) => {
        acc[e.version] = 1 + i;
        return acc;
      }, {});
    },
    getVersionAbbr() {
      return targetVersion => this.versions.find(
        ({ version }) => version === targetVersion,
      )?.abbr ?? targetVersion;
    },
    getYouTubeSearchLink() {
      return sheet => {
        if (sheet.searchUrl === null) {
          return null;
        }
        if (sheet.searchUrl !== undefined) {
          return sheet.searchUrl;
        }

        let escapedGameTitle = encodeURIComponent(
          gameTitle.replaceAll('-', '\\-'),
        );
        let escapedTitle = encodeURIComponent(
          sheet.title.replaceAll('-', '\\-'),
        );
        let escapedDifficulty = encodeURIComponent(
          this.getDifficultyName(sheet.difficulty),
        );

        return (
          'https://www.youtube.com/results'
          + `?search_query=${escapedGameTitle}+${escapedTitle}+${escapedDifficulty}`
        );
      };
    },
  },
});
