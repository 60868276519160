<template>
  <div>
    <v-pagination
      v-model="currentPage"
      :length="totalPage"
      :total-visible="10"
      circle
      class="pa-3"
    />
    <div
      class="d-flex flex-wrap justify-center"
      @contextmenu.prevent
    >
      <SheetTile
        v-for="(sheet, i) in sheetsInCurrentPage"
        :key="i"
        :sheet="sheet"
        :style="{ 'background-color': selectedSheets.includes(sheet) ? 'turquoise' : null }"
        @click.left="$emit('view-sheet', sheet);"
        @click.right="toggleSheetSelection(sheet);"
      />
    </div>
    <v-pagination
      v-model="currentPage"
      :length="totalPage"
      :total-visible="10"
      circle
      class="pa-3"
    />
  </div>
</template>

<script>
import SheetTile from '@/components/widgets/SheetTile.vue';

export default {
  components: {
    SheetTile,
  },
  props: {
    sheets: {
      type: Array,
      required: true,
    },
    selectedSheets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 48,
    };
  },
  computed: {
    totalPage() {
      return Math.ceil(this.sheets.length / this.itemsPerPage);
    },
    sheetsInCurrentPage() {
      let start = (this.currentPage - 1) * this.itemsPerPage;
      let end = start + this.itemsPerPage;
      return this.sheets.slice(start, end);
    },
  },
  watch: {
    sheets() {
      this.currentPage = 1;
    },
  },
  methods: {
    toggleSheetSelection(sheet) {
      if (this.selectedSheets.includes(sheet)) {
        this.$emit('update:selected-sheets', this.selectedSheets.filter(e => e !== sheet));
      } else {
        this.$emit('update:selected-sheets', [...this.selectedSheets, sheet]);
      }
    },
  },
};
</script>
