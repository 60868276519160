var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.opened,"max-width":"500px","overlay-color":_vm.isDarkMode ? 'white' : undefined,"overlay-opacity":_vm.isDarkMode ? 0.25 : undefined},on:{"input":function($event){return _vm.$emit('update:opened', $event);}}},[_c('v-card',[_c('v-img',{staticClass:"grey lighten-2",class:{ 'rainbow-background': _vm.sheet.isSpecial },attrs:{"contain":"","height":_vm.$vuetify.breakpoint.height < 750 ? '250px' : '350px',"src":_vm.imageSrc},on:{"error":function($event){_vm.imageErrorOccurred = true;}}},[_c('img',{staticStyle:{"position":"absolute","height":"100%","width":"100%","object-fit":"contain","opacity":"0"},attrs:{"src":_vm.imageSrc,"alt":""}}),(!_vm.isStatic)?_c('div',{staticClass:"d-flex justify-center align-center fill-height text-h1 white--text"},[_vm._v(" "+_vm._s(_vm.sheet.songNo)+" ")]):_c('div',{staticClass:"d-flex justify-end align-end fill-height pa-3"},[(_vm.sheet.searchUrl !== null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":_vm.$vuetify.breakpoint.xsOnly,"text":_vm.$vuetify.breakpoint.smAndUp,"dark":"","color":_vm.getSearchLinkColor(_vm.sheet),"href":_vm.getYouTubeSearchLink(_vm.sheet),"target":"_blank"},on:{"click":function($event){return _vm.$gtag.event('SheetVideoSearched', { value: 1 });}}},on),[_c('v-icon',{attrs:{"large":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v(" "+_vm._s(_vm.getSearchLinkIcon(_vm.sheet))+" ")])],1)]}}],null,false,119953526)},[_c('span',[_vm._v(_vm._s(_vm.sheet.searchUrl === undefined ? _vm.$t('searchOnYouTube') : ':3'))])]):_vm._e()],1),(_vm.getTypeIconUrl(_vm.sheet.type) != null)?_c('img',{staticStyle:{"position":"absolute","bottom":"10px","left":"15px"},attrs:{"src":_vm.getTypeIconUrl(_vm.sheet.type),"alt":((_vm.$t('key.type')) + ": " + (_vm.sheet.type))}}):_vm._e()]),_c('v-card-text',{staticClass:"py-5"},[_c('div',{staticClass:"d-flex flex-column justify-start align-end",staticStyle:{"position":"absolute","right":"24px"}},[(_vm.sheet.bpm !== undefined)?_c('h3',[_vm._v(" BPM "+_vm._s(_vm.sheet.bpm !== null ? _vm.sheet.bpm : '?')+" ")]):_vm._e(),_c('v-spacer',{staticClass:"py-1"}),(_vm.sheet.isLocked)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pa-1",attrs:{"large":""}},on),[_vm._v(" mdi-lock ")])]}}],null,false,2761288190)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.unlockNeeded')))])]):_vm._e(),_vm._l((_vm.regions),function(ref,i){
var region = ref.region;
var regionName = ref.name;
return [(_vm.sheet.regions && _vm.sheet.regions[region] === false)?_c('v-tooltip',{key:i,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pa-1",attrs:{"large":""}},on),[_vm._v(" "+_vm._s(region === 'jp' ? 'mdi-circle-off-outline' : '')+" "+_vm._s(region === 'intl' ? 'mdi-earth-off' : '')+" "+_vm._s(region === 'cn' ? 'mdi-earth-box-off' : '')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.unavailableInRegion', { region: regionName }))+" ")])]):_vm._e()]})],2),_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.sheet.gameTitle ? ((_vm.sheet.gameTitle) + " ＼ ") : null)+_vm._s((_vm.sheet.category || 'N/A').replaceAll('|', '｜'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('h1',_vm._g({staticClass:"py-2 text-truncate",class:{ 'text--primary': _vm.isStatic },staticStyle:{"cursor":"pointer","user-select":"all","margin-right":"42px"},on:{"click":function($event){return _vm.copyText(_vm.sheet.title);}}},on),[_vm._v(" "+_vm._s(_vm.sheet.title)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.sheet.title))])]),_c('h4',{staticClass:"pb-2 text-truncate",staticStyle:{"margin-right":"42px"}},[_vm._v(" "+_vm._s(_vm.sheet.artist || 'N/A')+" ")]),_c('h2',{staticClass:"py-2"},[_c('span',{class:{ 'text--primary': _vm.getDifficultyColor(_vm.sheet.difficulty) === 'unset' },style:({ 'color': _vm.getDifficultyColor(_vm.sheet.difficulty) })},[(_vm.getDifficultyIconUrl(_vm.sheet.difficulty) != null)?_c('span',[_c('img',{staticClass:"mr-1",staticStyle:{"vertical-align":"middle"},attrs:{"src":_vm.getDifficultyIconUrl(_vm.sheet.difficulty),"width":"25","height":"25","alt":((_vm.$t('key.difficulty')) + ": " + (_vm.sheet.difficulty))}})]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.getDifficultyName(_vm.sheet.difficulty))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.sheet.level)+" ")]),(_vm.sheet.internalLevel != null)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('small',_vm._g({staticClass:"text--secondary"},on),[_c('span',[_vm._v("("+_vm._s(_vm.sheet.internalLevel)+")")])])]}}],null,false,167137613)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('key.internalLevel'))}})]):_vm._e()],1)]),_c('h4',{staticClass:"pt-2 text-truncate"},[_vm._v(" "+_vm._s(_vm.$t('key.noteDesigner'))+": "+_vm._s(_vm.sheet.noteDesigner || 'N/A')+" ")]),_c('h4',{staticClass:"pt-2 text-truncate"},[_vm._v(" "+_vm._s(_vm.$t('key.releaseDate'))+": "+_vm._s(_vm.sheet.releaseDate || '????-??-??')+" ("+_vm._s(_vm.sheet.version || '???')+") ")])],1)]),_c('v-card-actions',[_c('v-spacer'),(_vm.isDrawMode)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('redraw-sheet');}}},[_vm._v(" "+_vm._s(_vm.$t('tryAgain'))+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.$emit('update:opened', false);}}},[_vm._v(" "+_vm._s(_vm.isDrawMode ? _vm.$t('ui.ok') : _vm.$t('ui.close'))+" ")])],1)],1),_c('v-snackbar',{attrs:{"timeout":750},model:{value:(_vm.copyHintOpened),callback:function ($$v) {_vm.copyHintOpened=$$v},expression:"copyHintOpened"}},[_c('v-icon',{attrs:{"left":"","color":"success"}},[_vm._v(" mdi-content-copy ")]),_vm._v(" "+_vm._s(_vm.$t('ui.copied'))+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }