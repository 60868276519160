<template>
  <div>
    <v-alert
      v-if="isLoading"
      type="info"
      dense outlined
    >
      {{ $t('ui.loading') }}
    </v-alert>
    <v-alert
      v-else-if="dataTime !== null"
      type="success"
      dense outlined
    >
      {{ $t('dataTime') }}{{ new Intl.DateTimeFormat().format(new Date(dataTime)) }}
    </v-alert>
    <v-alert
      v-else-if="errorMessage !== null"
      type="error"
      dense outlined
    >
      {{ $t('loadDataFailed') }} ({{ errorMessage }})
    </v-alert>
    <v-alert
      v-else
      type="warning"
      dense outlined
    >
      Unexpected Error.
    </v-alert>
  </div>
</template>

<script>
import * as Pinia from 'pinia';
import useRootStore from '@/stores/root';

export default {
  computed: {
    ...Pinia.mapState(useRootStore, [
      'dataTime',
      'errorMessage',
      'isLoading',
    ]),
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "dataTime": "Data Update Time: ",
    "loadDataFailed": "Failed while loading data."
  },
  "ja": {
    "dataTime": "データ更新日：",
    "loadDataFailed": "データの読み込みに失敗しました。"
  },
  "zh-Hant": {
    "dataTime": "資料更新日期：",
    "loadDataFailed": "資料讀取失敗。"
  },
  "zh-Hans": {
    "dataTime": "数据更新日期：",
    "loadDataFailed": "数据加载失败。"
  }
}
</i18n>
