import sleep from 'sleep-promise';
import { pickItems } from '@/utils';

export default class ItemDrawer {
  constructor({ drawSize = 1, defaultItem = null } = {}) {
    this.drawSize = drawSize;
    this.defaultItem = defaultItem;

    this.drawingPool = [];
    this.currentItems = [...Array(drawSize)].fill(defaultItem);

    this.isDrawing = false;
    this.isStopping = false;
    this.isRestarting = false;
  }

  static async* drawFrom(items, count, defaultItem) {
    if (items.length === 0) {
      yield [...Array(count)].fill(defaultItem);
      return;
    }
    if (items.length === 1) {
      yield [...Array(count)].fill(items[0]);
      return;
    }
    for (let v = 200; v > 0; v -= 5) {
      yield pickItems(items, count);
      // eslint-disable-next-line no-await-in-loop
      await sleep(4000 / v);
    }
  }

  async start() {
    if (this.isDrawing) {
      this.isRestarting = true;
      return false;
    }

    this.isDrawing = true;
    this.isStopping = false;

    do {
      this.isRestarting = false;
      let drawIterator = ItemDrawer.drawFrom(this.drawingPool, this.drawSize, this.defaultItem);
      // eslint-disable-next-line no-await-in-loop
      for await (let items of drawIterator) {
        if (this.isStopping || this.isRestarting) break;
        this.currentItems = items;
      }
    // eslint-disable-next-line no-constant-condition
    } while (this.isRestarting);

    this.isDrawing = false;
    return !this.isStopping;
  }

  stop() {
    this.isStopping = true;
  }

  setDrawingPool(items) {
    this.drawingPool = items;
  }

  setDrawSize(drawSize) {
    this.drawSize = drawSize;
    this.currentItems = [...Array(drawSize)].fill(this.defaultItem);
  }

  setStaticItems(items) {
    this.stop();
    this.currentItems = items;
  }
}
