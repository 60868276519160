<template>
  <v-dialog v-model="opened" max-width="500px">
    <template #activator="{ on }">
      <v-icon
        :aria-label="$t('help')"
        v-on="on"
      >
        mdi-help-circle-outline
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="headline" v-text="title" />
      <v-card-text>
        <h3 v-for="(text, i) in descriptions" :key="i" v-text="text" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text color="success"
          @click="opened = false;"
        >
          {{ $t('ui.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true,
    },
    descriptions: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "help": "Help"
  },
  "ja": {
    "help": "ヘルプ"
  },
  "zh-Hant": {
    "help": "幫助"
  },
  "zh-Hans": {
    "help": "帮助"
  }
}
</i18n>
