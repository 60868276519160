<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" class="d-flex justify-center align-center">
        <v-radio-group
          row
          :value="displayMode"
          @change="$emit('update:display-mode', $event);"
        >
          <v-radio value="grid">
            <template #label>
              <span>
                <v-icon>mdi-view-grid-outline</v-icon>
                {{ $t('gridMode') }}
              </span>
            </template>
          </v-radio>
          <v-radio value="table">
            <template #label>
              <span>
                <v-icon>mdi-view-list-outline</v-icon>
                {{ $t('tableMode') }}
              </span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex justify-center align-center">
        <div id="drawComboAnchor" />

        <v-radio-group
          row
          :value="listMode"
          @change="$emit('update:list-mode', $event);"
        >
          <v-radio value="filter">
            <template #label>
              <span>
                <v-icon>mdi-filter-menu-outline</v-icon>
                {{ $t('filterMode') }}
              </span>
            </template>
          </v-radio>
          <v-radio value="combo">
            <template #label>
              <span>
                <v-icon>mdi-shuffle-variant</v-icon>
                {{ $t('comboMode') }}
              </span>
            </template>
          </v-radio>
          <v-radio value="mylist">
            <template #label>
              <span>
                <v-icon>mdi-playlist-music</v-icon>
                {{ $t('mylistMode') }}
                ({{ selectedSheets.length }})
                <HelpButton
                  :title="$t('myListDescriptionTitle')"
                  :descriptions="$t('myListDescriptions')"
                />
              </span>
            </template>
          </v-radio>
        </v-radio-group>

        <template v-if="listMode === 'filter'">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon x-large color="green"
                class="mx-4"
                :aria-label="$t('copyFilterLink')"
                @click="copyFilterLink" v-on="on"
              >
                <v-icon>mdi-link-box-variant</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('copyFilterLink') }}</span>
          </v-tooltip>
        </template>
        <template v-if="listMode === 'combo'">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon x-large color="primary"
                class="mx-4"
                :aria-label="$t('changeComboSize')"
                @click="changeComboSize" v-on="on"
              >
                <v-icon>mdi-pound-box</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('changeComboSize') }}</span>
          </v-tooltip>
        </template>
        <template v-if="listMode === 'mylist'">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon large color="cyan"
                :aria-label="$t('exportMyList')"
                @click="exportSelectedSheets" v-on="on"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('exportMyList') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon large color="red"
                :aria-label="$t('importMyList')"
                @click="importSelectedSheets" v-on="on"
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('importMyList') }}</span>
          </v-tooltip>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as Pinia from 'pinia';
import useRootStore from '@/stores/root';
import FileSaver from 'file-saver';
import copy from 'copy-to-clipboard';
import selectFiles from 'select-files';
import QueryString from 'query-string';
import HelpButton from '@/components/widgets/HelpButton.vue';
import * as Utils from '@/utils';

export default {
  components: {
    HelpButton,
  },
  props: {
    displayMode: {
      type: String,
      required: true,
    },
    listMode: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      default: null,
    },
    comboDrawer: {
      type: Object,
      required: true,
    },
    selectedSheets: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...Pinia.mapState(useRootStore, [
      'sheetExprMapping',
    ]),
  },
  methods: {
    copyFilterLink() {
      let query = Utils.saveFiltersAsQuery(this.filters);

      if (Object.keys(query).length === 0) {
        // eslint-disable-next-line no-alert
        window.alert(this.$t('noFilterWarn'));
        return;
      }

      let url = QueryString.stringifyUrl({
        url: window.location.href,
        query,
      });
      copy(url, {
        format: 'text/plain',
      });

      // eslint-disable-next-line no-alert
      window.alert([
        url,
        this.$t('ui.copied'),
      ].join('\n'));
    },
    async exportSelectedSheets() {
      if (this.selectedSheets.length === 0) {
        // eslint-disable-next-line no-alert
        window.alert(this.$t('mylistEmptyWarn'));
        return;
      }

      try {
        let jsonText = JSON.stringify(
          [...this.selectedSheets.map(sheet => Utils.getSheetExpr(sheet))],
          null,
          '\t',
        );
        FileSaver.saveAs(
          new Blob([jsonText], { type: 'application/json; charset=utf-8;' }),
          `mylist-${Utils.toLocalISOString(new Date()).slice(0, 10).replaceAll('-', '')}.json`,
        );
        this.$gtag.event('ExportSelectedSheets');
      } catch (e) {
        // eslint-disable-next-line no-alert
        window.alert(`An error occurred while saving MY LIST:\n${e}`);
      }
    },
    async importSelectedSheets() {
      let files = await selectFiles({ accept: 'application/json', multiple: false });

      try {
        let [file] = files;
        let sheetExprs = [...new Set(JSON.parse(await file.text()))];
        let selectedSheets = sheetExprs.map(
          sheetExpr => this.sheetExprMapping.get(sheetExpr) ?? Utils.makeDummySheet(sheetExpr),
        );

        this.$emit('update:selected-sheets', selectedSheets);

        // eslint-disable-next-line no-alert
        window.alert(`${selectedSheets.length} sheet(s) loaded.`);
        this.$gtag.event('ImportSelectedSheets');
      } catch (e) {
        // eslint-disable-next-line no-alert
        window.alert(`An error occurred while loading '${files[0].name}':\n\n${e}`);
      }
    },
    changeComboSize() {
      const [minSize, maxSize] = [1, 48];

      // eslint-disable-next-line no-alert
      let input = window.prompt(
        this.$t('enterComboSize', { minSize, maxSize }),
        this.comboDrawer.drawSize,
      );

      if (input !== null) {
        let num = Math.min(Math.max(minSize, Number.parseInt(input, 10)), maxSize);

        if (!Number.isNaN(num)) {
          this.comboDrawer.stop();
          this.comboDrawer.setDrawSize(num);
        } else {
          // eslint-disable-next-line no-alert
          window.alert(this.$t('invalidComboSize', { input }));
        }
      }
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "tableMode": "List View",
    "gridMode": "Grid View",
    "filterMode": "FILTER MODE",
    "comboMode": "RANDOM SET",
    "mylistMode": "MY LIST",
    "copyFilterLink": "Copy current filters as URL",
    "changeComboSize": "Change number of sheets per set",
    "enterComboSize": "Enter the number of sheets per set ({minSize}~{maxSize}):",
    "invalidComboSize": "Invalid set size: {input}\nPlease enter a valid number.",
    "importMyList": "Import MY LIST from file",
    "exportMyList": "Export MY LIST to file",
    "noFilterWarn": "Please select at least one filter first.",
    "mylistEmptyWarn": "MY LIST is empty!\nNothing to export.",
    "myListDescriptionTitle": "How to use MY LIST",
    "myListDescriptions": [
      "In \"Grid View\", you can use right-click or long-press to add sheets.",
      "In \"List View\", you can use the checkboxes to add sheets.",
      "* Selected sheets will appear in MY LIST."
    ]
  },
  "ja": {
    "tableMode": "リスト表示",
    "gridMode": "グリッド表示",
    "filterMode": "フィルターモード",
    "comboMode": "ランダムセット",
    "mylistMode": "マイリスト",
    "copyFilterLink": "フィルターをリンクにコピーする",
    "changeComboSize": "セットサイズを変更する",
    "enterComboSize": "セットサイズを入力してください ({minSize}~{maxSize})：",
    "invalidComboSize": "無効なセットサイズ: {input}\n有効な数字を入力してください。",
    "importMyList": "マイリストをインポートする",
    "exportMyList": "マイリストをエクスポートする",
    "noFilterWarn": "まずはフィルターを一つ以上適用してください",
    "mylistEmptyWarn": "「マイリスト」は空です。\nエクスポートするものがありません。",
    "myListDescriptionTitle": "「マイリスト」の使い方",
    "myListDescriptions": [
      "「グリッド表示」では、右クリックまたは長押しで譜面を追加できます。",
      "「リスト表示」では、チェックボックスで譜面を追加できます。",
      "※選択した譜面が「マイリスト」に表示されます。"
    ]
  },
  "zh-Hant": {
    "tableMode": "列表檢視",
    "gridMode": "格狀檢視",
    "filterMode": "篩選模式",
    "comboMode": "隨機組曲",
    "mylistMode": "我的歌單",
    "copyFilterLink": "將當前篩選條件複製成連結",
    "changeComboSize": "變更隨機組曲數量",
    "enterComboSize": "請輸入隨機組曲數量 ({minSize}~{maxSize}):",
    "invalidComboSize": "無效的組曲數量: {input}\n請輸入有效的數字。",
    "importMyList": "從檔案匯入我的歌單",
    "exportMyList": "匯出我的歌單到檔案",
    "noFilterWarn": "請先指定至少一個篩選條件",
    "mylistEmptyWarn": "「我的歌單」是空的！\n沒有可以匯出的項目。",
    "myListDescriptionTitle": "「我的歌單」使用說明",
    "myListDescriptions": [
      "在【格狀檢視】模式中，可使用滑鼠右鍵或觸控長按來選取譜面。",
      "在【列表檢視】模式中，可勾選複選方框來選取譜面。",
      "之後切換到「我的歌單」就可以看到已選擇的譜面。"
    ]
  },
  "zh-Hans": {
    "tableMode": "列表检视",
    "gridMode": "格状检视",
    "filterMode": "筛选模式",
    "comboMode": "随机组曲",
    "mylistMode": "我的歌单",
    "copyFilterLink": "将当前筛选条件复制成链接",
    "changeComboSize": "变更随机组曲数量",
    "enterComboSize": "请输入随机组曲数量 ({minSize}~{maxSize}):",
    "invalidComboSize": "无效的组曲数量: {input}\n请输入有效的数字。",
    "importMyList": "从文件汇入我的歌单",
    "exportMyList": "汇出我的歌单到文件",
    "noFilterWarn": "请先指定至少一个筛选条件",
    "mylistEmptyWarn": "「我的歌单」是空的！\n没有可以汇出的项目。",
    "myListDescriptionTitle": "「我的歌单」使用说明",
    "myListDescriptions": [
      "在【格状检视】模式中，可通过鼠标右击或触屏长按来选取谱面。",
      "在【列表检视】模式中，可通过勾选复选框来选取谱面。",
      "之后切换到「我的歌单」就可以看到已选择的谱面。"
    ]
  }
}
</i18n>
